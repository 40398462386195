.agreement {
  background-size: contain;
  background-repeat: no-repeat;
  color: #020202;
  padding: 0 13px;
}

.agreement .about-img {
  position: relative;
  width: 82px;
  margin: 90px auto 56px;
  left: 14px;
}

.agreement .agreement-content {
  height: 524px;
}

.agreement p {
  margin-bottom: 0.5rem;
}

.agreement .divider {
  width: calc(100% + 13px);
  border: 1px solid #F4F4F4;
  margin-top: -13px;
  left: -13px;
}