button.login.code {
  position: absolute;
  right: 24px;
  height: 45px;
  font-size: 14px;
  color: white;
}

button.login.next {
  height: 45px;
  border-radius: 50px;
  font-size: 14px;
  background: linear-gradient(90deg, #B5F1FE 0%, #5EE1FE 100%);
}

button:disabled.login.next {
  color: #ffffff;
  background: #313743;
}

#cancel {
  background: transparent;
  color: #aa8964;
  filter: drop-shadow(0px 1px 1px rgba(172, 139, 103, 0.4));
  border: 1px solid;
  border-radius: 4px;
}

input.phone, input.code {
  height: 46px;
  padding: 0 24px;
  font-size: 16px;
  line-height: 16px;
  color: white;
  background: rgba(76, 82, 98, 0.6);
  border: 0.6px solid #474747;
  border-radius: 50px;
  box-sizing: border-box;
}

.validate {
  color: red;
  font-size: 12px;
  position: relative;
  transform: scale(0.83, 0.83);
}
