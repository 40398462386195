#poster-enter{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#poster-loading{
  position: absolute;
  font-size: 14px;
  line-height: 28px;
  color: black;
  /*-webkit-text-stroke: 1px #FFFFFF;*/
  /*text-shadow: 0px 0px 1px #FFFFFF;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}