.menu-bar {
  position: fixed;
  width: 100%;
  height: 56px;
  background: #000000;
  z-index: 1;
}

.menu-bar .menu-button {
  margin: 16px 11px;
}

.menu-bar .profile {
  margin: 20px 12px;
  font-size: 12px;
}