div.claim {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/background.png");
  background-size: cover;
  overflow: hidden;
}

.claim-container {
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.claim .title {
  position: relative;
  width: 375px;
  line-height: 19px;
}

.claim .card-back {
  width: 375px;
  height: 733px;
  margin: -145px 0 -200px;
}

.claim .loading-title {
  color: #1DD7D0;
  padding: 81px 0 91px 92px;
  line-height: 19px;
}

.claim .content {
  height: 67px;
  display: flex;
  flex-direction: column;
  align-items: center;
  mix-blend-mode: normal;
}

.claim .loading-content {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  mix-blend-mode: normal;
}

.claim .content .input-tip {
  color: #FFFFFF;
  opacity: 0.64;
  margin: 10px 0;
}

.claim .loading-content .input-tip {
  color: #FFFFFF;
  opacity: 0.64;
  margin: 29px 0;
}

.claim .content input {
  width: 244px;
  height: 35px;
  color: #ffffff;
  text-align: center;
  background: #313743;
  border-radius: 50px;
  margin: 16px;
}

.claim .control {
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.claim .control button.confirm {
  width: 244px;
  height: 35px;
  background: linear-gradient(90deg, #B4F1FE 0%, #60E1FE 100%);
  opacity: 1;
  border-radius: 35px;
}

.claim .control button.close {
  margin-top: 40px;
}



.fail {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/background.png");
  background-size: cover;
  overflow: hidden;
}

.fail .title {
  color: #CA2A2A;
  font-size: 28px;
  line-height: 16px;
}

.fail .subtitle {
  width: 293px;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
  background-image: url("../assets/claim/fail-background.png");
  background-size: cover;
}

.fail .control {
  margin-top: 28px;
}

.fail button.try-now {
  width: 120px;
  height: 32px;
  background: linear-gradient(90deg, #B4F1FE 0%, #60E1FE 100%);
  font-size: 15px;
  color: #000000;
  line-height: 17px;
  margin-right: 24px;
  border-radius: 32px;
}

.fail button.try-later {
  width: 120px;
  height: 32px;
  background: #313743;
  font-size: 15px;
  color: #ffffff;
  border-radius: 32px;
}

.fail button.re-input {
  width: 120px;
  height: 32px;
  margin-right: 23px;
  background: linear-gradient(90deg, #B4F1FE 0%, #60E1FE 100%);
  font-size: 15px;
  color: #000000;
  line-height: 17px;
  border-radius: 32px;
}

.fail button.close {
  margin-top: 74px;
}



.success {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/background.png");
  background-size: cover;
  overflow: hidden;
}

.success-container {
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success .card-back {
  width: 375px;
  height: 733px;
  margin: -135px 0 -200px;
}

.success .control {
  height: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.success button.to-detail {
  width: 244px;
  height: 35px;
  background: linear-gradient(90deg, #B4F1FE 0%, #60E1FE 100%);
  border-radius: 35px;
}

.success button.to-detail.special {
  background: linear-gradient(90deg, #CAEF5E 0%, #9DC722 100%);
}