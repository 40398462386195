.login-container {
  height: 375px;
  background: url("../assets/login/login-background.png");
  background-size: cover;
}

.home {
  min-height: 100vh;
  background-image: url("../assets/background.png");
  background-size: cover;
  position: relative;
  padding-bottom: 210px;
  overflow: hidden;
}

.home .nft-container {
  position: relative;
  background-image: url("../assets/home/home-nft-container.png");
  width: 375px;
  height: 490px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .nft {
  position: relative;
  width: 204px;
  top: -24px;
}

button.claim {
  width: 183px;
  height: 34px;
  background: linear-gradient(90.06deg, #B5F1FE 1.14%, #5EE1FE 99.68%);
  border-radius: 27px;
  background-size: contain;
  color: #060606;
  font-size: 14px;
  margin-top: -17px;
}

.home .notice {
  position: absolute;
  bottom: 21px;
  margin: 0 14px 21px;
  padding: 11px 15px;
  font-size: 12px;
  text-align: justify;
  text-align-last: center;
  background: linear-gradient(112.91deg, rgba(255, 255, 255, 0.102) 3.51%, rgba(255, 255, 255, 0) 111.71%);
  filter: drop-shadow(14.6469px 17.5762px 29.2937px rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(20.5056px);
}

.home .notice p {
  color: #FFFFFF;
  opacity: 0.5;
}

.home button.claim {
  z-index: 1;
}