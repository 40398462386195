.detail {
  color: #ffffff;
  min-height: 100vh;
  background-image: url("../assets/background.png");
  background-attachment: fixed;
  overflow: hidden;
}

.detail .back {
  position: absolute;
  left: 0;
  top: 8px;
  margin-left: 14px;
}

.detail .page-title {
  font-weight: 600;
  font-size: 18px;
  height: 27px;
}

.detail .contract {
  text-align: center;
  font-size: 14px;
  color: #1DD7D0;
  margin: 21px auto 0;
  word-break: break-all;
}

.detail .contract .address {
  color: white;
}

.detail .nft-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 361px;
  background-image: url("../assets/detail/light.png");
  margin: -30px 0 -10px;
}

.detail .nft-border {
  width: 179px;
  height: 254px;
  background-image: url("../assets/mynft/nft-border.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail img.nft {
  width: 163px;
  height: 231px;
}

.detail .nft-base {
  width: 258px;
  margin: auto;
}

.detail .nft-information {
  flex-basis: 349px;
  margin: 0 13px 8px;
  padding: 7px 22px 0;
  border: 1px solid #262626;
  box-sizing: border-box;
  background: linear-gradient(109.37deg, rgba(255, 255, 255, 0.13) 12.9%, rgba(255, 255, 255, 0.06) 77.62%);
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(20px);
}

.detail .information-title {
  color:#AFE9F6;
  font-size: 18px;
  line-height: 25.2px;
  text-align: center;
}

.detail .information-content {
  margin-top: 9px;
  font-size: 12px;
}

.detail .information-item {
  display: flex;
  flex-basis: 307px;
  justify-content: space-between;
  height: 18px;
  margin-bottom: 12px;
}

.detail .information-item .label {
  color: #959595;
}

.detail .notice {
  font-size: 12px;
  line-height: 16.8px;
  margin: 0 13px 18px;
  padding: 8px 20px;
  border: 1px solid #262626;
  box-sizing: border-box;
  text-align: justify;
  text-align-last: center;
  color:#959595;
  background: linear-gradient(109.37deg, rgba(255, 255, 255, 0.13) 12.9%, rgba(255, 255, 255, 0.06) 77.62%);
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(20px);
}

.detail .poster-generate {
  width: 327px;
  height: 45px;
  margin: auto;
  font-size: 14px;
  margin-bottom: 28px;
}

.detail .poster-generate button {
  width: 100%;
  height: 100%;
  color: #000000;
  background: linear-gradient(90deg, #B5F1FE 0%, #5EE1FE 98.93%);
  border-radius: 50px;
}