.my-nft {
  min-height: 100vh;
  background-image: url("../assets/background.png");
  background-attachment: fixed;
  position: relative;
  padding-bottom: 130px;
  overflow: hidden;
}

.back {
  position: absolute;
  left: 0;
  top: 5px;
  margin-left: 14px;
}

.title-bar {
  position: relative;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.my-nft .contract {
  text-align: center;
  font-size: 14px;
  color: #1DD7D0;
  margin: 21px auto 33px;
  word-break: break-all;
}

.my-nft .contract .address {
  color: white;
}

.my-nft .card-container {
  color: #ffffff;
}

.my-nft .nft-card {
  width: 159px;
  height: 226px;
  background-image: url("../assets/mynft/nft-border.png");
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-nft img.nft {
  width: 143px;
  height: 203px;
}

.my-nft .card-title {
  margin: 7px;
}

.my-nft .card-title .name {
  line-height: 20px;
}

.my-nft .card-title .address {
  line-height: 17px;
}

.my-nft .main {
  overflow: hidden;
}

.my-nft .notice {
  position: absolute;
  bottom: 21px;
  margin: 0 14px 21px;
  padding: 11px 15px;
  font-size: 12px;
  text-align: justify;
  text-align-last: center;
  background: linear-gradient(112.91deg, rgba(255, 255, 255, 0.102) 3.51%, rgba(255, 255, 255, 0) 111.71%);
  filter: drop-shadow(14.6469px 17.5762px 29.2937px rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(20.5056px);
}

.my-nft .notice p {
  color: #FFFFFF;
  opacity: 0.5;
}