.about {
  min-height: 100vh;
  background-image: url("../assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #FFFFFF;
  overflow: hidden;
}

.about .about-content {
  padding: 0 25px;
}

.about .about-title {
  position: relative;
  width: 82px;
  margin: 24px auto 41px;
  line-height: 24px;
  font-size: 34px;
}

.about p {
  margin-bottom: 0.5rem;
  line-height: 30px;
}

.about .divider {
  width: 20px;
  height: 2px;
  border: 1px solid #979797;
  margin: 26px 0;
}